<template>
  <div>
    <div class="row page-title">
      <label>Request {{ ClientCode }}</label>
    </div>
    <div class="page-details row">
      <div class="text-center text-danger col-lg-12" v-if="IsDataLoaded&&!IsApproveOrRejectEnabled">
        The Request is already completed.
      </div>
      <client-overview class="col-lg-9" :ClientRequest="ClientRequest"  :ID="id"></client-overview>
      <div class="col-lg-3 approval-side-section">
        <div class="row">
          <p class="approve-reject-label">Complete or Reject Client Request</p>
          <div class="approve-reject-content col-lg-12">
            <label for="completedcode">Client Code</label>
            <b-input
            v-bind:class="{
                  errorborder: $v.CompletedCode.$invalid && $v.CompletedCode.$dirty,
                  noterrorborder: !$v.CompletedCode.$invalid,
                }"
                @blur="$v.CompletedCode.$touch()"
                @focus="$v.CompletedCode.$reset()"
                 id="completedcode" minLength="3" maxLength="3" name="completedcode" v-model="CompletedCode" placeholder="Enter Client Code" />
             <div
            class="error-message-format required-field"
            v-if="!$v.CompletedCode.required && $v.CompletedCode.$dirty"
          >
            Required field
          </div>
          <div
            class="error-message-format required-field"
            v-if="!$v.CompletedCode.minLength && $v.CompletedCode.$dirty"
          >
            Input must be at least {{$v.CompletedCode.$params.minLength.min}} characters
          </div>
          <div
            class="error-message-format required-field"
            v-if="!$v.CompletedCode.maxLength && $v.CompletedCode.$dirty"
          >
            Please do not enter more than {{$v.CompletedCode.$params.maxLength.max}} characters
          </div>
          </div>
          <div class="text-right" style="width: 100%;padding-top: 10px;">
            <button
              v-if="IsApproveOrRejectEnabled"
              class="btn custom-button btn-primary"
              @click="Complete()"
            >
              Complete
            </button>
            <button
              v-else
              class="btn custom-button btn-primary"
              disabled="disabled"
              title="The request is already completed or rejected."
            >
              Complete
            </button>
          </div>
        </div>
        <div class="row reject-section">
          <label class="rejection-label">Rejection Comments</label>
          <b-textarea
            class="rejection-comments"
            placeholder="Enter Rejection Comments"
            v-model="Comments"
            v-bind:class="{
                  errorborder: !$v.Comments.required && $v.Comments.$dirty,
                  noterrorborder: $v.Comments.required,
                }"
            rows="5"
            @blur="SetDirty()"
            @focus="ResetDirty()"
          >
          </b-textarea>
           <div
            class="error-message-format required-field"
            v-if="!$v.Comments.required && $v.Comments.$dirty"
          >
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button
              v-if="IsApproveOrRejectEnabled "
              class="btn custom-button btn-outline-primary"
              @click="Reject()"
            >
              Reject
            </button>
            <button
              v-else
              class="btn custom-button btn-outline-primary"
              disabled="disabled"
              title="The request is already approved or rejected."
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-form-group class="text-right footer-buttons" v-if="!Error&&!HideFooterButtons">
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button
         class="btn custom-button btn-outline-primary"
        @click="ShowReject()" v-if="IsApproveOrRejectEnabled"
      >
        Reject
      </button>
       <button
       v-else
        class="btn custom-button btn-outline-primary" disabled="disabled"
        title="The request is already approved or rejected."
      >
        Reject
      </button>
      <button
        v-if="IsApproveOrRejectEnabled"
        class="btn custom-button btn-primary"
        @click="ShowComplete()"
      >
        Complete Request
      </button>
      <button
        v-else
        class="btn custom-button btn-primary"
        disabled="disabled"
        title="The request is already completed."
      >
        Complete Request
      </button>
    </b-form-group>
    <b-modal
      v-model="ShowModal"
      :no-close-on-backdrop="true"
      scrollable
      title="Complete Request"
    >
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">{{ ModalTitle }}</h5>
        <button class="close" @click="CancelModal()"></button>
      </template>
      <div
        class="d-block row col-lg-6 col-md-8 offset-lg-2 offset-lg-3 primary-content"
      >
        <b-row>
          <b-col>
            <label>Client Code</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input v-model="CompletedCode" placeholder="Enter Client Code" />
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelAdd()">
            Cancel
          </button>
          <button
            :disabled="$v.$invalid"
            class="text-uppercase btn custom-button btn-primary"
            @click="Complete()"
          >
            Complete
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="ShowRejectModal"
      :no-close-on-backdrop="true"
      scrollable
      title="Add Product"
    >
      <template v-slot:modal-header="{ close }">
        <h5 class="modal-title">{{ ModalTitle }}</h5>
        <button class="close" @click="CancelReject()"></button>
      </template>
      <div
        class="d-block row col-lg-6 col-md-8 offset-lg-2 offset-lg-3 primary-content"
      >
        <b-row>
          <b-col>
            <label>Rejection Comments</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="Comments" rows="5"> </b-textarea>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="text-right">
          <button class="btn btn-link text-uppercase" @click="CancelAdd()">
            Cancel
          </button>
          <button
            :disabled="$v.$invalid"
            class="text-uppercase btn custom-button btn-primary"
            @click="Reject()"
          >
            Reject
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import store from "@/store";
import * as status from "../../utilities/ClientWorkflowStates";
export default {
  name: "CompleteRequest",
  validations: {
    CompletedCode: {
      required: requiredIf(function () {
        return this.ClientCodeCompletion == true;
      }),
      minLength: minLength(2),
      maxLength: maxLength(3),
    },
    Comments: {
      required: requiredIf(function () {
        return this.RejectComments == true;
      }),
    },
  },
  data() {
    return {
      ClientRequest: {},
      Error: false,
      ShowModal: false,
      CompletedCode: null,
      DownloadableFiles: true,
      ShowRejectModal: false,
      Comments: "",
      HideFooterButtons:true,
      RejectComments:false,
      ClientCodeCompletion:false
    };
  },
  props: ["id"],
  computed: {
    IsCommentsEntered() {
      return this.Comments != null && this.Comments.length > 0 && this.Comments.toString().trim().length>0;
    },
    IsClientCodeEntered() {
      return this.CompletedCode != null && this.CompletedCode.length > 0 && this.CompletedCode.toString().trim().length>=3 && this.CompletedCode.toString().trim().length<=3;
    },
    ClientCode() {
      return this.ClientRequest ? this.ClientRequest.RequestedClientCode : "";
    },
    ModalTitle() {
      return `Complete Request ${this.ClientName}`;
    },
    canUpdateClientRequestCode() {
      return this.$store.getters.canUpdateClientRequestCode;
    },
    IsApproveOrRejectEnabled() {
      return (
         this.ClientRequest &&
         this.ClientRequest.TaskStatus == status.AxUpdateTheRequestWithSystemCode
      );
    },
    IsDataLoaded() {
      return this.ClientRequest && this.ClientRequest.RequestedClientCode ? true : false;
    },
  },
  mounted() {
    if (!this.canUpdateClientRequestCode) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    } else {
      this.$store
        .dispatch("client/GetClientRequestWithTaskDetails", parseInt(this.id))
        .then((res) => {
          this.ClientRequest = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else{
            this.Error = true;
            alert(err);
          }
        });
    }
  },
  methods: {
    SetDirty(){
  this.$v.Comments.$touch();
    },
    ResetDirty(){
  this.$v.Comments.$reset();
    },
    GetData() {
      return {
        ID: parseInt(this.id),
        Comments: this.Comments,
        ActiveTaskID: this.ClientRequest.ActiveTaskID,
        ProjectID: this.ClientRequest.OmniflowProjectID,
        ProcessID: this.ClientRequest.OmniflowProcessID,
        CompletedCode: this.CompletedCode,
      };
    },
    ShowComplete() {
      this.ShowModal = true;
    },
    CancelModal() {
      this.ShowModal = false;
    },
    ShowReject() {
      this.ShowRejectModal = true;
    },
    CancelReject() {
      this.ShowRejectModal = false;
    },
    Reject() {
      this.ShowRejectModal = false;
      this.ClientCodeCompletion=false;
      this.RejectComments=true;
      this.$v.Comments.$touch();
      if(!this.$v.$invalid){
      this.$store
        .dispatch("client/RejectRequestatLast", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `Request ${this.ClientCode} has been rejected`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
         if (ex.response) {
            this.SetErrorMessage(
              "Error while rejecting request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while rejecting request", ex);
          }
        });
      }
    },
    Complete() {
      this.ShowModal = false;
      this.RejectComments=false;
      this.ClientCodeCompletion=true;
      this.$v.CompletedCode.$touch();
      if(!this.$v.$invalid){
      this.$store
        .dispatch("client/Complete", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.ClientCode} has been completed`
          );
          this.$router.push("/ClientProduct");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while completing request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while completing request", ex);
          }
        });
      }
    },
    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.ClientCode}. The error is ${ex}`
      );
    },
    Cancel() {
      this.$router.push("/ClientProduct");
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 1200px) {
  .page-details {
    padding: 1rem 3rem 0px !important;
  }
}
.approve-reject-content {
    padding-left: 50px;
    padding-right: 0px;
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}

</style>